import { initializeAnalytics, setCommonEventProperties } from 'analytics_setup';
import { meta } from 'data';
import { checkIfCookiePresentAndLogin } from 'features/landing/helper';
import ThemeProvider from 'features/landing/pwa-utils/theme/WithTheme';
import useOnboardingEvents from 'features/onboarding/analytics';
import { useEffect } from 'react';
import { paramsToObject, parseQueryParams } from 'utils/browserUtils';
import Head from '../features/core/Head';
import Landing from '../features/landing';

interface HomeProps {
  isProd: boolean;
}

const Home: React.FC<HomeProps> = ({ isProd }) => {
  const { pageLoadEvent } = useOnboardingEvents();
  useEffect(() => {
    initializeAnalytics(false);
    const searchParamsObject = paramsToObject(
      parseQueryParams(location.search)
    );
    setCommonEventProperties(searchParamsObject);
    pageLoadEvent({ ...searchParamsObject });
  }, []);
  const isAuthenticationVerified = checkIfCookiePresentAndLogin();
  return !isAuthenticationVerified ? (
    <ThemeProvider>
      <Head {...meta} isProd={isProd} addFull addOg />
      <Landing showLoginPopup={false} />
    </ThemeProvider>
  ) : null;
};

export default Home;
