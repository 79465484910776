export const meta = {
  title:
    'Khatabook-Best Digital Bahi Khata App to Manage Your Business & Personal Ledgers',
  description:
    "Digital India's Digital Khata! Download Khatabook now & take charge of all your transactions, use Khatabook and grow your business!",
  keywords: 'khatabook, digital khata, khata'
};


export const lendingMeta= {
  title:
    'Small Business Loans - Quick & Easy Finance Solutions | Khatabook',
  description:
    "Unlock your business's potential with Khatabook's hassle-free loans. Quick application, fast approval, and flexible repayment options tailored for small businesses and MSME. Explore our loan solutions today.",
  keywords: 'small business loans, MSME loans, quick business finance, easy loan approval, flexible business loans, Khatabook loans, financial solutions for businesses, quick loan application process'
};
